const links = [
    {
        label: "Home",
        url: "/",
    },
    {
        label: "Sobre Mim",
        url: "/about/",
    },
    {
        label: "Meu Blog no WordPress",
        url: "https://esuguimo.com.br/",
    },
]

export default links
